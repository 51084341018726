import React from 'react';
import Slider from 'react-slick';
import { Card, CardMedia, CardContent, Typography, Box, IconButton, Link } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import newYork from '../images/new_york.jpg';
import paris from '../images/paris.jpg';
import london from '../images/london.jpg';
import lisbon from '../images/lisbon.jpg';
import singapore from '../images/singapore.jpg';
import istanbul from '../images/istanbul.jpg';
import korea from '../images/korea.jpg';
import brussels from '../images/brussels.jpg';
import bangkok from '../images/bangkok.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/slick_modifications.css"

const CityCarousel = () => {
    const cities = [
        { name: 'Devcon', image: bangkok, date: '12 July - 15 July', location: 'Bangkok, Thailand', groupLink: 'https://t.me/BEB_Devcon_Bangkok', isOver: false },
        { name: 'EthCC', image: brussels, date: '8 July - 11 July', location: 'Brussels, Belgium', groupLink: 'https://t.me/BEB_EthCC_Brussels', isOver: true },
        { name: 'DevConnect', image: istanbul, date: '12 Nov - 20 Nov', location: 'Istanbul, Turkey', groupLink: 'https://t.me/BEB_DevConnect_Istanbul', isOver: true },
        { name: 'Token2049', image: singapore, date: '11 Sept - 17 Sept', location: 'Singapore, Singapore', groupLink: 'https://t.me/BEB_Token2049_Singapore', isOver: true },
        { name: 'Korea BC Week', image: korea, date: '4 Sept - 10 Sept', location: 'Seoul, Korea', groupLink: 'https://t.me/BEB_KoreaBlockchainWeek2023', isOver: true },
    ];

  // const [currentSlide, setCurrentSlide] = React.useState(0);
  // const slideCount = cities.length;

  const NextArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          color: 'primary.main',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          fontSize: '30px',
          zIndex: 1,
          position: 'absolute', // Set the position to absolute
          top: '50%', // Center vertically
          right: '10px', // Adjust the distance from the right side
          transform: 'translateY(-50%)', // Center vertically
          // display: currentSlide >= slideCount - 2 ? 'none' : 'block',
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }
        }}
      >
        <ArrowForwardIosIcon fontSize="large" />
      </IconButton>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{

          color: 'primary.main',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          fontSize: '30px',
          zIndex: 1,
          position: 'absolute', // Set the position to absolute
          top: '50%', // Center vertically
          left: '10px', // Adjust the distance from the left side
          transform: 'translateY(-50%)', // Center vertically
          //display: currentSlide === 0 ? 'none' : 'block',
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }
        }}
      >
        <ArrowBackIosIcon
          fontSize="large"
          sx={{
            position: 'relative',
            left: '5px',
          }}
        />
      </IconButton>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: cities.length < 4 ? cities.length : 4,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: true,
    // beforeChange: (current, next) => setCurrentSlide(next),
    // nextArrow: <NextArrow currentSlide={currentSlide} slideCount={slideCount} />,
    // prevArrow: <PrevArrow currentSlide={currentSlide} />,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [ // an array of objects representing different breakpoints
      {
        breakpoint: 1200, // at max-width of 1200px
        settings: {
          slidesToShow: cities.length < 3 ? cities.length : 3, // show 3 slides
        }
      },
      {
        breakpoint: 900, // at max-width of 992px
        settings: {
          slidesToShow: cities.length < 2 ? cities.length : 2, // show 2 slides
        }
      },
      {
        breakpoint: 700, // at max-width of 768px
        settings: {
          slidesToShow: cities.length < 1 ? cities.length : 1, // show 1 slide
        }
      }
      // you can add more breakpoints here
    ],
  };

  return (
    <Box sx={{
      maxWidth: '86%',
      minWidth: '70%',
      margin: '0 auto',
      borderRadius: '10px',
      overflow: 'hidden',
      py: '10px',
      //backgroundColor: '#f5f5f5' // Change this color as per your requirement
    }}>
      <Slider {...settings}>
        {cities.map((city, index) => (
          <Box key={index} sx={{borderRadius: '30px', overflow: 'hidden'}}>
            <Link component="a" href={city.groupLink} target="_blank" rel="noopener noreferrer" underline="none">            {/* <a href={city.groupLink} target="_blank" rel="noopener norefferer"> */}
              <Card sx={{
                  borderRadius: '30px',
                  margin: '10px 10px',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transition for transform and shadow
                  filter: city.isOver ? 'grayscale(10%) opacity(50%)' : 'none',
                  '&:hover': city.isOver ? {} : {
                      transform: 'scale(1.05)', // Scales up the card to 105% of its size
                      boxShadow: '0px 4px 20px rgba(0,0,0,0.2)', // Adds shadow to make it pop out
                  }
              }}>
                <CardMedia
                  component="img"
                  height="220"
                  image={city.image}
                  alt={city.name}
                />
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "800" }} gutterBottom>{city.name}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EventIcon sx={{ pr: 1, color: 'grey.700' }} />
                    <Typography variant="subtitle1" >
                      {city.date}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ pr: 1, color: 'grey.700' }} />
                    <Typography variant="subtitle1">
                      {city.location}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Link>
            {/* </a> */}
          </Box>
        ))}
      </Slider>
    </Box>
  );
};



export default CityCarousel;
